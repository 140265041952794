
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-form {
  ::v-deep {
    .g-field,
    .skeleton {
      &.jobTitle {
        @include bp-lg-laptop {
          grid-column: 1 / 5;
        }
      }
      &.category {
        @include bp-lg-laptop {
          grid-column: 5 / 8;
        }
      }
      &.divisionId {
        @include bp-lg-laptop {
          grid-column: 8 / 11;
        }
      }
      &.street {
        @include bp-lg-laptop {
          grid-column: 1 / 5;
        }
      }
      &.city {
        @include bp-lg-laptop {
          grid-column: 5 / 9;
        }
      }
      &.state {
        @include bp-lg-laptop {
          grid-column: 9 / 11;
        }
      }
      &.degrees {
        @include bp-lg-laptop {
          grid-column: 1 / 7;
        }
      }
      &.requiresTravel {
        @include bp-lg-laptop {
          grid-column: 7 / 11;
          margin-left: 40px;
        }
      }
      &.keywords {
        @include bp-lg-laptop {
          grid-column: 1 / 9;
        }
      }
      &.description {
        .input-element {
          min-height: 132px;
        }
      }
    }
  }
}
